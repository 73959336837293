import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencyInnerhtml',
  standalone: true,
})
export class CurrencyInnerhtmlPipe implements PipeTransform {
  transform(value: number, mode: 'full' | 'short' = 'short'): string {
    const formattedValue = value.toFixed(3);
    const [whole, decimal] = formattedValue.split('.');
    const mainDecimal = decimal.slice(0, 2);
    const lastDigit = decimal[2];
    
    // Add commas to the whole number
    const wholeWithCommas = whole.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    if (mode === 'short') {
      return `<span class="short"><sup class="dollar">$</sup>${wholeWithCommas}.${mainDecimal}</span>`;
    }

    return `<span class="full"><sup class="dollar">$</sup>${wholeWithCommas}.${mainDecimal}<sup class="last-digit">${lastDigit}</sup></span>`;
  }
}
