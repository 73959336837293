// Every url slug should mention this enum else the backbutton not work on application

export enum URLS {
  ROOT = "",
  LOGIN = "sign-in",
  SIGNUP = "sign-up",
  ABOUT_US = "about-us",
  FAQ = "faqs",
  CONTACT_US = "contact-us",
  TERMS_CONDITIONS = "terms-conditions",
  PRIVACY_POLICY = "privacy-policy",
  USER_ACCOUNT = "account",
  ORDER_LOGIN = "order-login",
  CONFIRM_ADDRESS = "confirm-address",
  PAYMENT = 'payment',
  ORDERS = "orders",
  ORDER_DETAILS = "order-details",
  OIL_PROVIDER_SELECTOR = "oil-select-provider",
  FUEL_ADDITIVE = "fuel-additive",
  DELIVERY_DETAILS = "delivery-details",
  TANK_PHOTO_TOOL = 'tank-photo-tool',
  UPDATE_TANK_PHOTO = 'update-oil-tank-info',
  GOD_LOGIN = "set-auth",
  REFER = "refer",
  REFER_INFO = "info-refer",
  WALLET = "wallet",
  PRICING = "pricing",
  SERVICE_AREA = "service-area",
  NOT_FOUND = "404",
}

// For Note to add new url
// 'refer', 'refer-info', 'refer-etc' will be consider same page in mobile navigation
// to bypass do this 'refer', 'info-refer', 'etc-refer' first segment should always be different
