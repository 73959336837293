import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[phoneNumberFormatter]',
  standalone: true
})
export class PhoneNumberFormatterDirective {

  constructor(
    private el: ElementRef
  ) {
  }

  ngAfterViewInit() {
    this.formatPhoneNumber(this.el.nativeElement.querySelector('input').value);
  }

  @HostListener('input', ['$event.target.value'])
  onInput(value: string) {
    this.formatPhoneNumber(value);
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    event.preventDefault();
    const pasteData = (event.clipboardData?.getData('text') || '').replace(/\D/g, '');
    const phoneRegex = /^(\d{3})(\d{3})(\d{4})$/; // Define regex as a variable
    const phoneFormat = '($1) $2-$3'; // Define format as a variable
    let formattedValue = '';

    const inputElement = this.el.nativeElement.querySelector('input');

    if (pasteData.length > 10) {
      formattedValue = pasteData.slice(-10).replace(phoneRegex, phoneFormat);
    } else if (pasteData.length === 10) {
      formattedValue = pasteData.replace(phoneRegex, phoneFormat);
    } else {
      formattedValue = pasteData;
    }

    inputElement.value = formattedValue;
    inputElement.dispatchEvent(new Event('input', { bubbles: true }));
  }

  formatPhoneNumber(value: string = '') {
    value = value.replace(/\D/g, ''); // Remove non-numeric characters

    let formatted = '';
    for (let i = 0; i < value.length; i++) {
      if (i === 0 && value !== '') {
        formatted = '(' + value[i];
      } else if (i === 3) {
        formatted += ') ' + value[i];
      } else if (i === 6) {
        formatted += '-' + value[i];
      } else {
        formatted += value[i];
      }
    }

    const maxLength = 14;

    if (formatted.length <= maxLength) {
      this.el.nativeElement.querySelector('input').value = formatted;
    }
  }
}
