import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class HeatfleetHeaderService {

  constructor(private http: HttpClient) {}

  isMenuOpen = false;
  isPageMenuOpen = false;
  isSecure: boolean = false;
  isCompany: boolean = false;
  companyName: string = 'Heat fleet';
  companyCookieDomain: string | undefined = '';
  apiUrl: string = '';
  urls: any;
  clientUrl: string = '';
  isIntialized: boolean = false;
  fullLogoUrl: string = '';
  iconLogoUrl: string = '';
  nameLogoUrl: string = '';

  isReferralEnabled: boolean = false;

  initHeatFleetHeader(project: 'secure' | 'seo', companyName: string, urls: any, client_url: string, full_logo_url: string, icon_logo_url: string, name_logo_url: string, api_url: string, cookieDomain? :string) {
    if (project === 'secure') {
      this.isSecure = true;
    } else {
      this.isSecure = false;
    }
    this.urls = urls;
    this.clientUrl = client_url;
    this.apiUrl = api_url;
    this.isIntialized = true;
    this.fullLogoUrl = full_logo_url;
    this.iconLogoUrl = icon_logo_url;
    this.nameLogoUrl = name_logo_url;
    if (companyName !== '') {
      this.isCompany = true;
      this.companyName = companyName;
      this.companyCookieDomain = cookieDomain;
    } else {
      this.isCompany = false;
      this.companyName = 'Heat fleet';
    }
    this.getConfig();
  }

  getConfig() {
    let headers: HttpHeaders = new HttpHeaders({
      'X-Api-Key': `oiwe43raiasdl4kha6sdf123`,
      'DAVOS-IsMobile': "false",
      'Authorization': 'Bearer ',
    })

    const url = `${this.apiUrl}user-configuration`;
    this.http.get(url, { headers }).subscribe({
      next: (res: any) => {
        this.isReferralEnabled = res['isReferralProgramEnabled'];
      },
      error: (error) => {
        this.isReferralEnabled = false;
        console.log(error);
      }
    })
  }

  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  togglePageMenu() {
    this.isPageMenuOpen = !this.isPageMenuOpen;
  }

}
