import { ApplicationConfig } from '@angular/core';
import { provideRouter, withInMemoryScrolling } from '@angular/router';
import { appRoutes } from './app.routes';
import { provideClientHydration, withHttpTransferCacheOptions } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import { ApiInterceptor } from './shared/interceptors/interceptor';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { provideNgxStripe } from 'ngx-stripe';
import { environment } from '../environments/environment';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(appRoutes, withInMemoryScrolling({ scrollPositionRestoration: 'enabled' })),
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
    provideClientHydration(),
    provideHttpClient(withFetch(), withInterceptorsFromDi()),
    provideClientHydration(withHttpTransferCacheOptions({ includePostRequests: true, })),
    provideNgxStripe(environment.stripe_publishable_key),
  ],
};
