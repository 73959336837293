import { isPlatformServer, Location } from '@angular/common';
import { Inject, Injectable, NgZone, PLATFORM_ID } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';
import { App } from '@capacitor/app';
import { NavigationStart, Router } from '@angular/router';
import { CookieService } from './cookie.service';

@Injectable({
  providedIn: 'root'
})

export class AppService {
  isServerRendering: boolean = false;
  isBrowserRendering: boolean = false;
  isIosApp: boolean = false;
  isAndroidApp: boolean = false;
  isApp: boolean = false;
  isWebApp: boolean = false;
  isLocal: boolean = false;
  devicePixelRatio: number = 1;
  deviceInfo: any = {};
  allPages: Array<string> = [];
  pageRegex: RegExp = new RegExp(`//`, 'g');
  pageStack: Array<string> = [];
  navigationStack: Array<string> = [];
  isInitNav: boolean = true;

  constructor(
    private location: Location,
    private zone: NgZone,
    @Inject(PLATFORM_ID) private platformId: any,
    private router: Router,
    private cookie: CookieService
  ) {
  }

  setPlatform(isLocal: boolean) {
    if (isPlatformServer(this.platformId)) {
      this.isServerRendering = true;
      this.isBrowserRendering = false;
      this.devicePixelRatio = 1;
    } else {
      this.isBrowserRendering = true;
      this.isServerRendering = false;
      this.devicePixelRatio = window.devicePixelRatio;
    }
    if (Capacitor.getPlatform() === 'ios') {
      this.isApp = true;
      this.isIosApp = true;
      this.isAndroidApp = false;
      this.isWebApp = false;
    } else if (Capacitor.getPlatform() === 'android') {
      this.isApp = true;
      this.isIosApp = false;
      this.isAndroidApp = true;
      this.isWebApp = false;
    } else if (Capacitor.getPlatform() === 'web') {
      this.isApp = false;
      this.isIosApp = false;
      this.isAndroidApp = false;
      this.isWebApp = true;
    }
    this.isLocal = isLocal;
  }

  initBackButton(urls: any) {
    if (this.isWebApp) {
      return;
    }
    this.allPages = Object.values(urls);
    const pattern = this.allPages.filter(page => page !== '').map(page => page.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&')).join('|');
    this.pageRegex = new RegExp(`/${pattern}/`, 'g');
    // During app startup
    this.router.events.subscribe((event) => {
      // During app navigation
      if (event instanceof NavigationStart) {
        this.addUrlIntoNavigationStack(event.url);
      }
    });
    if (this.isAndroidApp) {
      App.addListener('backButton', () => {
        this.zone.run(() => {
          if (this.pageStack.length > 0) {
            this.pageStack.pop();
            this.navigationStack.pop();
            if (this.pageStack.length > 0) {
              const isProviderPage = this.providerPageNavigation(this.navigationStack[this.navigationStack.length - 1]);

              if (isProviderPage) {
                this.router.navigate([isProviderPage]);
              } else {
                this.router.navigate([this.navigationStack[this.navigationStack.length - 1]]);
              }
            } else {
              this.router.navigate(['']);
            }
          } else {
            App.exitApp();
          }
        });
      });
    }
    if (this.isIosApp) {
      this.location.subscribe(event => {
        if (event.pop) {
          this.zone.run(() => {
            if (this.pageStack.length > 0) {
              this.pageStack.pop();
              this.navigationStack.pop();
            }
            if (this.pageStack.length === 0) {
              history.replaceState(null, '', '');
            }
          });
        }
      });
    }
  }

  addUrlIntoNavigationStack(url: string) {
    if (this.isWebApp) {
      return;
    }
    if (url == "/" || url == "") {
      this.pageStack = [];
      this.navigationStack = [];
      if (!this.isInitNav) {
        this.isInitNav = true;
        history.go(-history.length + 1);
      }
    }
    this.isInitNav = false;
    const matches = url.match(this.pageRegex);
    if (matches && matches.length > 0) {
      const existingOnStack = this.pageStack.indexOf(matches[0]);
      if (existingOnStack !== -1) {
        // If the route is found, remove all items from that index onward
        this.pageStack.splice(existingOnStack + 1);
        this.navigationStack.splice(existingOnStack + 1);
      } else {
        // If not found, just push the new route
        this.pageStack.push(matches[0]);
        this.navigationStack.push(url);
      }
    }
  }

  resetNavigationStack() {
    this.pageStack = [];
    this.navigationStack = [];
  }

  providerPageNavigation(url: string): string | null {
    const providerPageRegex = /^\/oil-select-provider\/(\d+)\/(\d+)\/(\d+)\/(\d+)\/(\d+)$/;

    if (!providerPageRegex.test(url)) {
      return null; // Return null if the URL does not match the pattern
    }

    return url.replace(providerPageRegex, (_, zipCode, tankSize, customerType, orderType, quantity) => {
      const newZipCode = this.cookie.get('zip_code') ? this.cookie.get('zip_code') : zipCode;
      const newQuantity = this.cookie.get('gallons') ? this.cookie.get('gallons') : quantity;
      return `/oil-select-provider/${newZipCode}/${tankSize}/${customerType}/${orderType}/${newQuantity}`;
    });
  }

  async getDeviceInfo() {
    const deviceInfo = await Device.getInfo();
    this.deviceInfo = deviceInfo;
  }
}
