import { DavosEnvironment } from "../app/shared/models/interface";

const local: DavosEnvironment = {
  is_local: true,
  api_url: 'https://dev-api.heatfleet.com/api/',
  api_version: '1.0',
  api_key: 'oiwe43raiasdl4kha6sdf123',
  vendor_url: 'https://dev.systemstar.com/',
  client_url: 'https://dev.heatfleet.com/',
  secure_url: 'https://dev-secure.heatfleet.com/',
  tokenization_key: '24x5CN-Y34yun-9cy3V7-t5MAZ2',
  paypal_client_id:
    'AcFQTHo45inLdsXIgxTqiPRQiHyHJWynxvJTnSjJWVuMc6eyuNTP_edPjghjHAhC2gK8dbPjhACWwdIx',
  cryptojs_key: "LpckS4LLOta7PPb+2kk5Oq1xcPeEYpjz4ENKjtN2+R0=",
  cryptojs_iv: "IoZgQvJHbo/etR0xU0lwfQ==",
  hof_api_url: "https://dev-api.heatingoilfinder.com/api/",
  env_type: "dev"
}

const dev: DavosEnvironment = {
  is_local: false,
  api_url: 'https://dev-api.heatfleet.com/api/',
  api_version: '1.0',
  api_key: 'oiwe43raiasdl4kha6sdf123',
  vendor_url: 'https://dev.systemstar.com/',
  client_url: 'https://dev.heatfleet.com/',
  secure_url: 'https://dev-secure.heatfleet.com/',
  tokenization_key: '24x5CN-Y34yun-9cy3V7-t5MAZ2',
  paypal_client_id:
    'AcFQTHo45inLdsXIgxTqiPRQiHyHJWynxvJTnSjJWVuMc6eyuNTP_edPjghjHAhC2gK8dbPjhACWwdIx',
  cryptojs_key: "LpckS4LLOta7PPb+2kk5Oq1xcPeEYpjz4ENKjtN2+R0=",
  cryptojs_iv: "IoZgQvJHbo/etR0xU0lwfQ==",
  hof_api_url: "https://dev-api.heatingoilfinder.com/api/",
  env_type: "dev"
}

const uat: DavosEnvironment = {
  is_local: false,
  api_url: 'https://demo-api.heatfleet.com/api/',
  api_version: '1.0',
  api_key: 'oiwe43raiasdl4kha6sdf123',
  vendor_url: 'https://demo.systemstar.com/',
  client_url: 'https://demo.heatfleet.com/',
  secure_url: 'https://demo-secure.heatfleet.com/',
  tokenization_key: '24x5CN-Y34yun-9cy3V7-t5MAZ2',
  paypal_client_id:
    'AcFQTHo45inLdsXIgxTqiPRQiHyHJWynxvJTnSjJWVuMc6eyuNTP_edPjghjHAhC2gK8dbPjhACWwdIx',
  cryptojs_key: "LpckS4LLOta7PPb+2kk5Oq1xcPeEYpjz4ENKjtN2+R0=",
  cryptojs_iv: "IoZgQvJHbo/etR0xU0lwfQ==",
  hof_api_url: "https://demo-api.heatingoilfinder.com/api/",
  env_type: "uat"
}

const main: DavosEnvironment = {
  is_local: false,
  api_url: 'https://api.heatfleet.com/api/',
  api_version: '1.0',
  api_key: 'oiwe43raiasdl4kha6sdf123',
  vendor_url: 'https://systemstar.com/',
  client_url: 'https://heatfleet.com/',
  secure_url: 'https://secure.heatfleet.com/',
  tokenization_key: '922Z9E-q4QWUc-2j2968-c2Fx28',
  paypal_client_id:
    'AVd9gVKUYrf1BAU0M2gRAebZcl4IH7G_FZ4_HsW_YGD1WXHagnH7J5EGvS7GS2KNY680njuGYmEH7rAR',
  cryptojs_key: "LpckS4LLOta7PPb+2kk5Oq1xcPeEYpjz4ENKjtN2+R0=",
  cryptojs_iv: "IoZgQvJHbo/etR0xU0lwfQ==",
  hof_api_url: "https://api.heatingoilfinder.com/api/",
  env_type: "main"
}

export const environment = dev;
