import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { HfToastComponent, HfToastService } from '@monorepo/ui';
import { environment } from '../environments/environment';
import { AppService, AuthService, NotificationService, StoreService } from '@monorepo/utils';
import { SafeArea } from 'capacitor-plugin-safe-area';
import { Meta } from '@angular/platform-browser';
import { appSettings, isCompanyApp } from './shared/utils/app.static';
import { FirebaseMessaging } from '@capacitor-firebase/messaging';
import { URLS } from './shared/utils/urls';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, HfToastComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {

  constructor(
    public authService: AuthService,
    public appService: AppService,
    public storeService: StoreService,
    private toastService: HfToastService,
    private router: Router,
    private notificationService: NotificationService,
    private meta: Meta,
  ) {
    if (this.appService.isBrowserRendering) {
      this.storeService.initStore();
    }
  }

  ngOnInit() {
    this.appService.setPlatform(environment.is_local);
    if (this.appService.isBrowserRendering) {
      this.appService.getDeviceInfo();
      this.appService.initBackButton(URLS);
      window.addEventListener('offline', () => {
        this.toastService.toastMessage.next({ title: `Please check your internet connection.`, msg: 'If you still have an issue, please contact Provider Services at 631-SELL-OIL or ProviderServices@HeatFleet.com', type: 'error' });
      });
    }
    this.authService.setAuth('secure', environment.api_url, environment.api_version, environment.cryptojs_key, environment.cryptojs_iv);
    if (this.appService.isIosApp) {
      SafeArea.getStatusBarHeight().then(({ statusBarHeight }) => {
        document.documentElement.style.setProperty('--status-bar-height', `${statusBarHeight}px`);
      });
    }
    if (this.appService.isApp) {
      this.notificationService.initNotificationService();
      FirebaseMessaging.addListener("notificationActionPerformed", (event) => {
        if (event) {
          this.router.navigate([URLS.ORDERS]).then(() => {
            window.location.reload();
          });
        }
      });
    }
    if (isCompanyApp) {
      this.authService.companyCookieDomain = appSettings.cookieDomain;
    }

    this.meta.updateTag({
      name: 'robots',
      content: 'noindex, nofollow',
    });
  }
}
